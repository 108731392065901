import {reject} from 'lodash';
import {createSlice} from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
import parseQueryParams from 'src/utils/query';

// ----------------------------------------------------------------------


const initialState = {
  isLoading: false,
  error: false,
  quotes: [],
  quote: [],
  sortBy: null,
};

const slice = createSlice({
  name: 'quotes',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET QUOTES
    getQuotesSuccess(state, action) {
      state.isLoading = false;
      state.quotes = action.payload;
    },

    resetQuotesList(state) {
      state.quotes = [];
    },

    // GET QUOTE
    getQuoteSuccess(state, action) {
      state.isLoading = false;
      state.quote = action.payload;
    },

    // CLEAR QUOTE
    clearQuote(state, action) {
      state.isLoading = false;
      state.quote = null;
    },

    // DELETE QUOTE
    deleteQuote(state, action) {
      state.quotes = reject(state.quotes, {id: action.payload});
    },

    //  SORT & FILTER QUOTE
    sortByQuotes(state, action) {
      state.sortBy = action.payload;
    },
  }
});

// Reducer
export default slice.reducer;

// Actions
export const {
  getQuotesSuccess,
  resetQuotesList,
  getQuoteSuccess,
  clearQuote,
  deleteQuote,
  sortByQuotes,
} = slice.actions;

// ----------------------------------------------------------------------

export function getQuotes(queryParams) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`ecommerce/sales/cart/?${parseQueryParams(queryParams)}`);
      dispatch(slice.actions.getQuotesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getQuote(product_id, startLoad = true) {
  return async (dispatch) => {
    if (startLoad) {
      dispatch(slice.actions.startLoading());
    }
    try {
      const response = await axios.get(`/ecommerce/sales/cart/${product_id}/?expand=company,seller`);
      dispatch(slice.actions.getQuoteSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
