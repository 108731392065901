import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
import mailReducer from './slices/mail';
import chatReducer from './slices/chat';
import blogReducer from './slices/blog';
import userReducer from './slices/user';
import productReducer from './slices/product';
import quoteReducer from './slices/quote';
import notificationsReducer from './slices/notifications';
import calendarReducer from './slices/calendar';
import kanbanReducer from './slices/kanban';
import activityReducer from './slices/activity';
import orderReducer from './slices/order';
import companyReducer from './slices/company';

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: []
};

const productPersistConfig = {
  key: 'product',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout']
};

const quotePersistConfig = {
  key: 'quote',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy']
};

const rootReducer = combineReducers({
  mail: mailReducer,
  order: orderReducer,
  company: companyReducer,
  chat: chatReducer,
  blog: blogReducer,
  user: userReducer,
  calendar: calendarReducer,
  kanban: kanbanReducer,
  activity: activityReducer,
  product: persistReducer(productPersistConfig, productReducer),
  quote: persistReducer(quotePersistConfig, quoteReducer),
  notifications: persistReducer(productPersistConfig, notificationsReducer)
});

export { rootPersistConfig, rootReducer };
