import {createSlice} from '@reduxjs/toolkit';
import parseQueryParams from 'src/utils/query';
// utils
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  orders: [],
  order: null,
};

const slice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET ORDERS
    getOrdersSuccess(state, action) {
      state.isLoading = false;
      state.orders = action.payload;
    },

    deleteOrderProductSuccess(state, action) {
      state.isLoading = false;
    },

    deleteOrderItemSuccess(state, action) {
      state.isLoading = false;
    },

    // GET ORDER DETAILS
    getOrderSuccess(state, action) {
      state.isLoading = false;
      state.order = action.payload;
      // state.order.payments = state.order.payments.reverse();
    },

    // GET ORDER DETAILS
    resetOrder(state, action) {
      state.order = null;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const {
  resetOrder
} = slice.actions;

// ----------------------------------------------------------------------

export function getOrders(queryParams) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/ecommerce/sales/order/?${parseQueryParams(queryParams)}`);
      dispatch(slice.actions.getOrdersSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getOrder(order_uuid) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/ecommerce/sales/order/${order_uuid}/?expand=items.variants,items.category_fields,items.category_fields.variant_category,items.variants.variant_category,items,items.product,items.order_products,items.order_products.status,items.product.main_image,items.images,seller,company,client,status,payments,shipment_address,billing_info`);
      dispatch(slice.actions.getOrderSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteOrderProduct(order_uuid, order_product_id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.delete(`/ecommerce/sales/order_product/${order_product_id}/`);
      dispatch(slice.actions.deleteOrderProductSuccess());
      dispatch(getOrder(order_uuid));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteOrderItem(order_uuid, order_item_id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.delete(`/ecommerce/sales/order_item/${order_item_id}/`);
      dispatch(slice.actions.deleteOrderItemSuccess());
      dispatch(getOrder(order_uuid));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
