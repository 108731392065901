import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
// hooks
import useAuth from '../hooks/useAuth';
// pages
import Login from '../pages/authentication/Login';

// ----------------------------------------------------------------------

AnyRoleGuard.propTypes = {
  children: PropTypes.node
};

const hasAnyRole = (roleFunctions, user) => {
  for (const role of roleFunctions) {
    if (user[role]) {
      return true;
    }
  }

  return false;
}

export default function AnyRoleGuard({ roleFunctions, children }) {
  const { isAuthenticated, user } = useAuth();

  if (!isAuthenticated) {
    return <Login />;
  }

  if (!hasAnyRole(roleFunctions, user)) {
    return <Navigate to="/dashboard" />;
  }

  return <>{children}</>;
}
