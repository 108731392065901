// material
import {Autocomplete, Box, TextField} from '@mui/material';
// routes
// hooks
// components
import {useDispatch, useSelector} from "../../redux/store";
import {setSelectedCompany} from "../../redux/slices/company";
import {useSearchParams} from "react-router-dom";
import {useCallback} from "react";

// ----------------------------------------------------------------------

export default function CompanySelector() {
  const dispatch = useDispatch();
  // eslint-disable-next-line no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();
  const {selectedCompany, companies} = useSelector((state) => state.company);

  const handleChangeCompany = useCallback((company) => {
    if (!company) {
      company = {name: 'Todas', id: 0};
    }

    if (company?.id !== 0) {
       if (companies?.length > 1) {
        setSearchParams({"c": encodeURIComponent(company.name)});
        dispatch(setSelectedCompany(company));
       }
    } else {
      setSearchParams({});
      dispatch(setSelectedCompany(null));
    }
  }, [companies, dispatch, setSearchParams]);

  return companies?.length > 1 ? (
    <Box mx={3}>
      <Autocomplete
        size="sm"
        value={selectedCompany?.id ? selectedCompany : {name: 'Todas', id: 0}}
        onChange={(ev_, val) => handleChangeCompany(val)}
        options={[{name: 'Todas', id: 0}, ...companies]}
        isOptionEqualToValue={(opt, value) => opt.id === value.id}
        getOptionLabel={(opt) => opt.name}
        renderInput={(params) =>
          <TextField
            {...params}
            label="Sucursal"
          />
        }
      />
    </Box>
  ) : '';
}
