import {lazy, Suspense} from 'react';
import {Navigate, useLocation, useRoutes} from 'react-router-dom';
// layouts
import MainLayout from '../layouts/main';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
import AdminGuard from "../guards/AdminGuard";
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// components
import LoadingScreen from '../components/LoadingScreen';
import AdminStaffGuard from "../guards/AdminStaffGuard";
import AnyRoleGuard from "../guards/AnyRoleGuard";

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const {pathname} = useLocation();
  const isDashboard = pathname.includes('/dashboard');

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: 'fixed'
            })
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login/>
            </GuestGuard>
          )
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register/>
            </GuestGuard>
          )
        },
        {path: 'login-unprotected', element: <Login/>},
        {path: 'register-unprotected', element: <Register/>},
        {path: 'reset-password', element: <ResetPassword/>},
        {path: 'verify', element: <VerifyCode/>}
      ]
    },

    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout/>
        </AuthGuard>
      ),
      children: [
        {element: <Navigate to="/dashboard/app" replace/>},
        {
          path: 'app', element:
            <AdminGuard>
              <GeneralApp/>
            </AdminGuard>
        },
        // { path: 'products/:product_id', element: <GeneralApp /> },
        {path: 'orders', element: <OrdersList/>},
        {path: 'orders/:order_uuid', element: <OrderDetails/>},
        {path: 'quotes', element: <QuotesPage/>},
        {path: 'quotes/:quote_uuid', element: <QuoteDetailsPage/>},
        {path: 'calendar', element: <CalendarPage/>},
        {path: 'calendar/:order_uuid', element: <CalendarPage/>},
        {path: 'products', element: <ProductsList/>},
        {path: 'add-product', element: <ProductForm/>},
        {path: 'products/:product_id', element: <ProductEditForm/>},
        {
          path: 'kanban', element:
            <AnyRoleGuard roleFunctions={[
              "is_superuser", "is_staff", "is_operator"
            ]}>
              <Kanban/>
            </AnyRoleGuard>
        },
        {
          path: 'activity', element:
            <AdminStaffGuard>
              <ActivityPage/>
            </AdminStaffGuard>
        },
        {
          path: 'activity/:order_uuid', element:
            <AdminStaffGuard>
              <ActivityPage/>
            </AdminStaffGuard>
        },
        {path: 'notifications', element: <NotificationsList/>},
      ]
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout/>,
      children: [
        {path: 'coming-soon', element: <ComingSoon/>},
        {path: 'maintenance', element: <Maintenance/>},
        {path: 'pricing', element: <Pricing/>},
        {path: 'payment', element: <Payment/>},
        {path: '500', element: <Page500/>},
        {path: '404', element: <NotFound/>},
        {path: '*', element: <Navigate to="/404" replace/>}
      ]
    },
    {
      path: '/',
      element: <MainLayout/>,
      children: [
        {
          path: '/', element: (
            <GuestGuard>
              <Login/>
            </GuestGuard>
          )
        }
      ]
    },
    {path: '*', element: <Navigate to="/404" replace/>}
  ]);
}

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import('../pages/authentication/Login')));
const Register = Loadable(lazy(() => import('../pages/authentication/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/authentication/ResetPassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/authentication/VerifyCode')));
// Dashboard
const GeneralApp = Loadable(lazy(() => import('../pages/dashboard/GeneralApp')));
const OrderDetails = Loadable(lazy(() => import('src/pages/dashboard/Orders/OrderDetails')));
const OrdersList = Loadable(lazy(() => import('src/components/Orders/OrdersList')));
const QuoteDetailsPage = Loadable(lazy(() => import('src/components/OrderCapture/QuoteDetailsPage')));
const QuotesPage = Loadable(lazy(() => import('src/components/Quotes/QuotesList')));
const CalendarPage = Loadable(lazy(() => import('src/components/Calendar/CalendarPage')));
const ProductForm = Loadable(lazy(() => import('src/components/Products/ProductForm')));
const ProductEditForm = Loadable(lazy(() => import('src/components/Products/ProductEditForm')));
const ProductsList = Loadable(lazy(() => import('src/components/Products/ProductsList')));
const NotificationsList = Loadable(lazy(() => import('src/components/NotificationsList')));
const Kanban = Loadable(lazy(() => import('../pages/dashboard/Kanban')));
const ActivityPage = Loadable(lazy(() => import('../pages/dashboard/ActivityPage')));
// Main
const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')));
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
const Pricing = Loadable(lazy(() => import('../pages/Pricing')));
const Payment = Loadable(lazy(() => import('../pages/Payment')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
// Components
//