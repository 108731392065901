import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
// hooks
import useAuth from '../hooks/useAuth';
// pages
import Login from '../pages/authentication/Login';

// ----------------------------------------------------------------------

AdminStaffGuard.propTypes = {
  children: PropTypes.node
};

export default function AdminStaffGuard({ children }) {
  const { isAuthenticated, user } = useAuth();

  if (!isAuthenticated) {
    return <Login />;
  }

  if (!user.is_superuser && !user.is_staff) {
    return <Navigate to="/dashboard/orders/" />;
  }

  return <>{children}</>;
}
