import {createSlice} from '@reduxjs/toolkit';
import {omit} from 'lodash';
// utils
import axios from '../../utils/axios';
import axiosInstance from '../../utils/axios';
import {moveCardsToColumns, parseOrderProductCards} from "../../utils/kanban";
import parseQueryParams from "../../utils/query";

// ----------------------------------------------------------------------

function objFromArray(array, key = 'id') {
  return array.reduce((accumulator, current) => {
    accumulator[current[key]] = current;
    return accumulator;
  }, {});
}

const initialState = {
  isLoading: false,
  error: false,
  board: {
    cards: {},
    columns: {},
    columnOrder: [],
    validFromStatuses: [],
  },
  kanbanFilters: {
    requirements: {
      name: 'Cualquiera',
      value: 0
    },
    state: {
      name: 'Todos',
      value: 0
    },
    edited: {
      name: 'Todos',
      value: null
    }
  },
};

const slice = createSlice({
  name: 'kanban',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET BOARD
    getBoardSuccess(state, action) {
      state.isLoading = false;
      const board = action.payload;
      const cards = objFromArray(board.cards);
      const columns = objFromArray(board.columns);
      const validFromStatus = board.validFromStatus;
      const {columnOrder} = board;
      state.board = {
        cards,
        columns,
        columnOrder,
        validFromStatus
      };
    },

    // CREATE NEW COLUMN
    createColumnSuccess(state, action) {
      const newColumn = action.payload;
      state.isLoading = false;
      state.board.columns = {
        ...state.board.columns,
        [newColumn.id]: newColumn
      };
      state.board.columnOrder.push(newColumn.id);
    },

    getProductOrderSuccess(state, action) {
      state.openProductOrder = action.payload;
      state.isLoading = false;
    },

    updateCards(state, action) {
      const {cards} = action.payload;
      state.board.cards = cards;
    },

    persistCard(state, action) {
      const {columns} = action.payload;
      state.board.columns = columns;
    },

    persistColumn(state, action) {
      state.board.columnOrder = action.payload;
    },

    addTask(state, action) {
      const {card, columnId} = action.payload;

      state.board.cards[card.id] = card;
      state.board.columns[columnId].cardIds.push(card.id);
    },

    deleteTask(state, action) {
      const {cardId, columnId} = action.payload;

      state.board.columns[columnId].cardIds = state.board.columns[columnId].cardIds.filter((id) => id !== cardId);
      state.board.cards = omit(state.board.cards, [cardId]);
    },

    // UPDATE COLUMN
    updateColumnSuccess(state, action) {
      const column = action.payload;

      state.isLoading = false;
      state.board.columns[column.id] = {
        ...state.board.columns[column.id],
        name: column.name
      };
    },

    // UPDATE Notes
    updateProductOrderNotesSuccess(state, action) {
      state.openProductOrder.notes = [action.payload, ...state.openProductOrder.notes];
    },

    // DELETE COLUMN
    deleteColumnSuccess(state, action) {
      const {columnId} = action.payload;
      const deletedColumn = state.board.columns[columnId];

      state.isLoading = false;
      state.board.columns = omit(state.board.columns, [columnId]);
      state.board.cards = omit(state.board.cards, [...deletedColumn.cardIds]);
      state.board.columnOrder = state.board.columnOrder.filter((c) => c !== columnId);
    },

    resetProductOrder(state, action) {
      state.isLoading = false;
      state.openProductOrder = {};
    },

    setRequirementStatusSuccess(state, action) {
      const {cardId, checked} = action.payload;
      const newCards = {...state.board.cards};

      newCards[cardId] = {
        ...newCards[cardId],
        completed_requirements: newCards[cardId].completed_requirements + (checked ? 1 : -1)
      }

      state.board = {
        ...state.board,
        cards: newCards
      };
    },

    setKanbanFilters(state, action) {
      state.isLoading = false;
      state.kanbanFilters = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const {
  resetProductOrder,
  setKanbanFilters
} = slice.actions;

// ----------------------------------------------------------------------

export function getBoard(queryParams) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const {data: product_orders} = await axios.get(`ecommerce/sales/kanban_board/?${queryParams}`);
      const cards = parseOrderProductCards(product_orders);

      const {data: statuses} = await axios.get(`kanban/status/`);
      const columns = moveCardsToColumns(cards, statuses);
      const columnOrder = columns.map(s => s.id);

      const {data: status_permissions} = await axios.get(`kanban/status_permissions/`);
      const validFromStatus = status_permissions.map(s => s.from_status);

      dispatch(slice.actions.getBoardSuccess({cards, columns, columnOrder, validFromStatus}));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createColumn(newColumn) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/api/kanban/columns/new', newColumn);
      dispatch(slice.actions.createColumnSuccess(response.data.column));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateColumn(columnId, updateColumn) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axiosInstance.patch(`kanban/status/${columnId}/`, {
        ...updateColumn
      });
      dispatch(slice.actions.updateColumnSuccess(response.data.column));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteColumn(columnId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.post('/api/kanban/columns/delete', {columnId});
      dispatch(slice.actions.deleteColumnSuccess({columnId}));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getProductOrder(productOrderId, cardId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const params = parseQueryParams({
        expand: [
          'order_item', 'order_item.images', 'order_item.order', 'order_item.product', 'order_item.order.client',
          'order_item.category_fields', 'order_item.category_fields.variant_category', 'order_item.variants',
          'order_item.variants.variant_category', 'order_item.product.main_image', 'order_item.product.category',
          'status', 'requirements', 'notes', 'notes.author', 'status_steps', 'status_steps.user', 'status_steps.status'
        ]
      });
      const {data} = await axiosInstance.get(`ecommerce/sales/order_product/${productOrderId}/?${params}`);
      dispatch(slice.actions.getProductOrderSuccess({...data, cardId: cardId}));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function setRequirementStatus(cardId, requirementId, checked) {
  return async (dispatch) => {
    await axiosInstance.patch(`ecommerce/sales/order_product_requirement/${requirementId}/`, {completed: checked});
    dispatch(slice.actions.setRequirementStatusSuccess({cardId, checked}));
  };
}

// ----------------------------------------------------------------------

export function persistColumn(newColumnOrder) {
  return (dispatch) => {
    dispatch(slice.actions.persistColumn(newColumnOrder));
  };
}

// ----------------------------------------------------------------------

export function updateProductOrderNotes(newColumnOrder) {
  return (dispatch) => {
    dispatch(slice.actions.updateProductOrderNotesSuccess(newColumnOrder));
  };
}

// ----------------------------------------------------------------------

export function persistCard(columns) {
  return (dispatch) => {
    dispatch(slice.actions.persistCard(columns));
  };
}

// ----------------------------------------------------------------------

export function updateCards(cards) {
  return (dispatch) => {
    dispatch(slice.actions.updateCards(cards));
  };
}

// ----------------------------------------------------------------------

export function addTask(task) {
  return (dispatch) => {
    dispatch(slice.actions.addTask(task));
  };
}

// ----------------------------------------------------------------------

export function deleteTask(taskId) {
  return (dispatch) => {
    dispatch(slice.actions.deleteTask(taskId));
  };
}
