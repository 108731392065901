import {filter, map, reject, sum} from 'lodash';
import {createSlice} from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
import parseQueryParams from 'src/utils/query';
import {getOrder} from "./order";

// ----------------------------------------------------------------------

const CART_API_PARAMS = `?expand=company,items.product.main_image,items.images,items.category_fields,variants,items.product_combination,items.category_fields,items.category_fields.variant_category,items.product_combination.variants,items.product_combination.variants.variant_category,client,shipment_address,billing_info`;

const initialState = {
  isLoading: false,
  error: false,
  products: [],
  combinations: [],
  categoryCustomFields: {},
  variants: [],
  categories: [],
  categoriesList: [],
  product: null,
  productDetail: null,
  sortBy: null,
  updatePrice: null,
  filters: {
    gender: [],
    category: 'All',
    colors: [],
    priceRange: '',
    rating: ''
  },
  checkout: {
    activeStep: -1,
    cart: [],
    subtotal: 0,
    total: 0,
    discount: 0,
    shipping: 0,
    billing_info: null,
    client: null,
    shipment_address: null,
    total_taxes: 0
  },
  files: [],
  productPrices: {},
  productCompany: {},
};

const slice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PRODUCTS
    getProductsSuccess(state, action) {
      state.isLoading = false;
      state.products = action.payload;
    },

    // GET PRODUCT
    getProductSuccess(state, action) {
      state.isLoading = false;
      state.product = action.payload;
    },

    // GET PRODUCT DETAIL
    getProductDetailSuccess(state, action) {
      state.isLoading = false;
      state.productDetail = action.payload;
    },

    // CLEAR PRODUCT
    clearProduct(state, action) {
      state.isLoading = false;
      state.product = null;
    },

    // DELETE PRODUCT
    deleteProduct(state, action) {
      state.products = reject(state.products, {id: action.payload});
    },

    //  SORT & FILTER PRODUCTS
    sortByProducts(state, action) {
      state.sortBy = action.payload;
    },

    setCombinations(state, action) {
      state.combinations = action.payload;
    },

    setUpdatePrice(state, action) {
      state.updatePrice = action.payload;
    },

    setVariants(state, action) {
      state.variants = action.payload;
    },

    setCategories(state, action) {
      state.categories = action.payload;
    },

    getCategoriesSuccess(state, action) {
      state.categoriesList = action.payload;
    },

    filterProducts(state, action) {
      state.filters.gender = action.payload.gender;
      state.filters.category = action.payload.category;
      state.filters.colors = action.payload.colors;
      state.filters.priceRange = action.payload.priceRange;
      state.filters.rating = action.payload.rating;
    },

    // CHECKOUT
    getCartSuccess(state, action) {
      const checkout = action.payload;
      const client = checkout.client;
      const cart = checkout.items;

      const subtotal = sum(cart.map(cart_item => cart_item.price * cart_item.quantity));
      const discount = cart.length === 0 ? 0 : checkout.discount;
      const shipping = checkout.shipping;

      state.checkout = {...state.checkout, ...checkout};
      state.checkout.cart = cart;
      state.checkout.client = client;
      state.checkout.discount = discount;
      state.checkout.total_discount = checkout.total_discount;
      state.checkout.shipping = shipping;
      state.checkout.shipment_address = checkout.shipment_address;
      state.checkout.delivery_date = checkout.delivery_date ? new Date(checkout.delivery_date) : null;
      state.checkout.billing_info = checkout.billing_info;
      state.checkout.subtotal = subtotal;
      state.checkout.total = checkout.total;
      state.checkout.total_taxes = checkout.total_taxes;
      state.checkout.taxes = checkout.taxes;
      state.isLoading = false;
    },

    deleteCart(state, action) {
      state.checkout.cart = filter(state.checkout.cart, (item) => item.id !== action.payload);
    },

    resetCart(state) {
      state.checkout.activeStep = 0;
      state.checkout.cart = [];
      state.checkout.total = 0;
      state.checkout.subtotal = 0;
      state.checkout.discount = 0;
      state.checkout.shipping = 0;
      state.checkout.billing_info = null;
      state.checkout.shipment_address = null;
      state.checkout.client = null;
    },

    onBackStep(state) {
      state.checkout.activeStep -= 1;
    },

    onNextStep(state) {
      state.checkout.activeStep += 1;
    },

    onGotoStep(state, action) {
      const goToStep = action.payload;
      state.checkout.activeStep = goToStep;
    },

    increaseQuantity(state, action) {
      const productId = action.payload;
      const updateCart = map(state.checkout.cart, (product) => {
        if (product.id === productId) {
          return {
            ...product,
            quantity: product.quantity + 1
          };
        }
        return product;
      });

      state.checkout.cart = updateCart;
    },

    decreaseQuantity(state, action) {
      const productId = action.payload;
      const updateCart = map(state.checkout.cart, (product) => {
        if (product.id === productId) {
          return {
            ...product,
            quantity: product.quantity - 1
          };
        }
        return product;
      });

      state.checkout.cart = updateCart;
    },

    createBilling(state, action) {
      state.checkout.billing_info = action.payload;
    },

    applyDiscount(state, action) {
      state.checkout.discount = action.payload;
    },

    applyShipping(state, action) {
      const shipping = action.payload;
      state.checkout.shipping = shipping;
      state.checkout.total = state.checkout.subtotal - state.checkout.discount + shipping;
    },

    setFiles(state, action) {
      state.files = action.payload;
    },

    setProductPrices(state, action) {
      state.productPrices = action.payload;
    },

    setProductCompany(state, action) {
      state.productCompany = action.payload;
    },

    setCategoryCustomFields(state, action) {
      state.categoryCustomFields = action.payload;
    },

    resetProductList(state) {
      state.products = [];
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const {
  addCart,
  clearProduct,
  resetCart,
  onGotoStep,
  onBackStep,
  onNextStep,
  deleteCart,
  deleteProduct,
  createBilling,
  applyShipping,
  applyDiscount,
  filterProducts,
  sortByProducts,
  increaseQuantity,
  setFiles,
  decreaseQuantity,
  setCombinations,
  setVariants,
  getTotal,
  setCategories,
  setProductPrices,
  setProductCompany,
  setUpdatePrice,
  setCategoryCustomFields,
  resetProductList
} = slice.actions;

// ----------------------------------------------------------------------

export function getProducts(queryParams) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`ecommerce/store/product/?${parseQueryParams(queryParams)}`);
      dispatch(slice.actions.getProductsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getProduct(product_id, startLoad = true) {
  return async (dispatch) => {
    if (startLoad) {
      dispatch(slice.actions.startLoading());
    }
    try {
      const response = await axios.get(`/ecommerce/store/product/${product_id}/?expand=companies,category,images,variants.variant.variant_category,prices`);
      dispatch(slice.actions.getProductSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getProductDetail(product_id, startLoad = true) {
  return async (dispatch) => {
    if (startLoad) {
      dispatch(slice.actions.startLoading());
    }
    try {
      const response = await axios.get(`/ecommerce/store/product/${product_id}/?expand=companies,category,images,variants.variant.variant_category,prices`);
      dispatch(slice.actions.getProductSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function addToCart(itemData, companyId, categoryCustomFields) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {

      const cartResponse = await axios.get(`/ecommerce/sales/cart/${itemData.cart}/?fields=id&company=${companyId}`);
      const cartId = cartResponse.data.id;

      const data = {
        cart: cartId,
        product: itemData.product,
        product_combination: itemData.combination,
        isometric_data: itemData.isometric_field_values,
        quantity: itemData.quantity,
        price: itemData.price,
        subtotal: itemData.subtotal,
        custom_name: itemData.custom_name,
        custom_description: itemData.custom_description,
        additional_information: itemData.additional_information,
      }

      const itemResponse = await axios.post(`/ecommerce/cart/cart_item/`, data);
      if (itemResponse.status === 201) {
        if (Object.keys(categoryCustomFields).length > 0) {
          const promises = [];
          for (let [categoryId, categoryData] of Object.entries(categoryCustomFields)) {
            for (let [fieldName, fieldValue] of Object.entries(categoryData.fields)) {
              const fieldData = {
                cart_item: itemResponse.data?.id,
                variant_category: categoryId,
                category_name: categoryData.name,
                field_name: fieldName,
                field_value: fieldValue.toString()
              }
              promises.push(axios.post(`/ecommerce/cart/cart_item_category_field/`, fieldData));
            }
          }
          await Promise.all(promises);
        }
        dispatch(getCart(itemData.cart));
      }
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addProductToOrder(itemData, order, categoryCustomFields) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {

      const data = {
        order: order.id,
        product: itemData.product,
        product_combination: itemData.combination,
        isometric_data: itemData.isometric_field_values,
        quantity: itemData.quantity,
        price: itemData.price,
        subtotal: itemData.subtotal,
        custom_name: itemData.custom_name,
        custom_description: itemData.custom_description,
        additional_information: itemData.additional_information,
      }

      const itemResponse = await axios.post(`/ecommerce/sales/order_item/`, data);
      if (itemResponse.status === 201) {
        if (Object.keys(categoryCustomFields).length > 0) {
          const promises = [];
          for (let [categoryId, categoryData] of Object.entries(categoryCustomFields)) {
            for (let [fieldName, fieldValue] of Object.entries(categoryData.fields)) {
              const fieldData = {
                order_item: itemResponse.data?.id,
                variant_category: categoryId,
                category_name: categoryData.name,
                field_name: fieldName,
                field_value: fieldValue.toString()
              }
              promises.push(axios.post(`/ecommerce/sales/order_item_category_field/`, fieldData));
            }
          }
          await Promise.all(promises);
        }
        dispatch(getOrder(order.uuid));
      }
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteToCart(itemid, cartUUID) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.delete(`/ecommerce/cart/cart_item/${itemid}/`);
      dispatch(getCart(cartUUID));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function quantityItem(itemid, quantity) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      let dataToSend = {
        quantity: quantity,
      };
      await axios.patch(`/ecommerce/cart/cart_item/${itemid}/`, dataToSend);
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function handleApplyDiscount(cartUUID, discount) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      let dataToSend = {
        discount: discount,
      };
      const response = await axios.post(`/ecommerce/sales/cart/${cartUUID}/apply_discount/`, dataToSend);
      if (response.status === 200) {
        const cartResponse = await axios.get(`/ecommerce/sales/cart/${cartUUID}/${CART_API_PARAMS}`);
        if (cartResponse.status === 200) {
          dispatch(slice.actions.getCartSuccess(cartResponse.data));
        }
      }
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function handleEditShipmentCost(cartUUID, cost) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      let dataToSend = {
        shipping: cost,
      };
      const response = await axios.post(`/ecommerce/sales/cart/${cartUUID}/shipping_cost/`, dataToSend);
      if (response.status === 200) {
        const cartResponse = await axios.get(`/ecommerce/sales/cart/${cartUUID}/${CART_API_PARAMS}`);
        if (cartResponse.status === 200) {
          dispatch(slice.actions.getCartSuccess(cartResponse.data));
        }
      }
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getCart(cartUUID) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const cartResponse = await axios.get(`/ecommerce/sales/cart/${cartUUID}/${CART_API_PARAMS}`);
      if (cartResponse.status === 200) {
        dispatch(slice.actions.getCartSuccess(cartResponse.data));
      }
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getCategories() {
  return async (dispatch) => {
    try {
      const {data} = await axios.get(`ecommerce/store/variant_category/`);
      dispatch(slice.actions.getCategoriesSuccess(data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
