import {createSlice} from '@reduxjs/toolkit';
import axiosInstance from '../../utils/axios';
// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  activities: [],
  nextPage: 1,
  activityFilters: {
    actor: {
      name: 'Todos',
      value: 0
    },
    company: {
      name: 'Todas',
      value: 0
    },
  },
};

const slice = createSlice({
  name: 'activity',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getActivitiesSuccess(state, action) {
      state.isLoading = false;
      const {data, clear} = action.payload;

      if (clear) {
        state.activities = data?.results;
        state.nextPage = 1;
      } else {
        state.activities = state.activities.concat(data?.results);
      }

      if (data.next) {
        state.nextPage += 1;
      } else {
        state.nextPage = null;
      }
    },

    setActivityFilters(state, action) {
      state.isLoading = false;
      state.activityFilters = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const {
  setActivityFilters
} = slice.actions;

// ----------------------------------------------------------------------

export function getActivities(queryParams, clear) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const {data} = await axiosInstance.get(`activity/logs/?${queryParams}`);
      dispatch(slice.actions.getActivitiesSuccess({data, clear}));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
