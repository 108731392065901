import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  companies: [],
  selectedCompany: null,
  initialLoad: false,
};

const slice = createSlice({
  name: 'company',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getCompaniesSuccess(state, action) {
      state.isLoading = false;
      state.companies = action.payload.companies;
      if (action.payload.initialLoad !== null) {
        state.initialLoad = action.payload.initialLoad;
      }
    },

    setSelectedCompany(state, action) {
      state.isLoading = false;
      state.selectedCompany = {...action.payload};
    },
  }
});

// Reducer
export default slice.reducer;

// Actions
export const {
  getCompaniesSuccess,
  setSelectedCompany,
} = slice.actions;

// ----------------------------------------------------------------------

export function getCompanies(initialLoad = null) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/companies/`);
      dispatch(slice.actions.getCompaniesSuccess({companies: response.data, initialLoad}));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
