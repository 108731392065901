import {useEffect, useRef} from 'react';
import {useNavigate} from 'react-router-dom';
import { Icon } from '@iconify/react';
import bellFill from '@iconify/icons-eva/bell-fill';
// material
import {
  Badge,
} from '@mui/material';
// utils
// components
import { MIconButton } from '../../components/@material-extend';
import {PATH_DASHBOARD} from "../../routes/paths";
import {useDispatch, useSelector} from "../../redux/store";
import axiosInstance from "../../utils/axios";
import useAuth from "../../hooks/useAuth";
import {setTotalUnRead} from "../../redux/slices/notifications";

// ----------------------------------------------------------------------

export default function NotificationsPopover() {
  const anchorRef = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { totalUnRead } = useSelector((state) => state.notifications);
  const { user } = useAuth();


  const _getTotalUnRead = async () => {
    const response = await axiosInstance.get(`notifications/notification/?user=${user.id}&page_size=1&seen=false`)
    dispatch(setTotalUnRead(response.data.count));
  }

  useEffect(() => {
    _getTotalUnRead();
  }, []) // eslint-disable-line

  return (
    <>
      <MIconButton
        ref={anchorRef}
        size="large"
        color={'default'}
        onClick={() => navigate(PATH_DASHBOARD.notifications)}
        // sx={{
        //   ...(open && {
        //     bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.focusOpacity)
        //   })
        // }}
      >
        <Badge badgeContent={totalUnRead} color="error">
          <Icon icon={bellFill} width={20} height={20} />
        </Badge>
      </MIconButton>

  {/*    <MenuPopover open={open} onClose={handleClose} anchorEl={anchorRef.current} sx={{ width: 360 }}>*/}
  {/*      <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>*/}
  {/*        <Box sx={{ flexGrow: 1 }}>*/}
  {/*          <Typography variant="subtitle1">Notifications</Typography>*/}
  {/*          <Typography variant="body2" sx={{ color: 'text.secondary' }}>*/}
  {/*            You have {totalUnRead} unread messages*/}
  {/*          </Typography>*/}
  {/*        </Box>*/}

  {/*        {totalUnRead > 0 && (*/}
  {/*          <Tooltip title=" Mark all as read">*/}
  {/*            <MIconButton color="primary" onClick={handleMarkAllAsRead}>*/}
  {/*              <Icon icon={doneAllFill} width={20} height={20} />*/}
  {/*            </MIconButton>*/}
  {/*          </Tooltip>*/}
  {/*        )}*/}
  {/*      </Box>*/}

  {/*      <Divider />*/}

  {/*      <Scrollbar sx={{ height: { xs: 340, sm: 'auto' } }}>*/}
  {/*        <List*/}
  {/*          disablePadding*/}
  {/*          subheader={*/}
  {/*            <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>*/}
  {/*              New*/}
  {/*            </ListSubheader>*/}
  {/*          }*/}
  {/*        >*/}
  {/*          {notifications.slice(0, 2).map((notification) => (*/}
  {/*            <NotificationItem key={notification.id} notification={notification} />*/}
  {/*          ))}*/}
  {/*        </List>*/}

  {/*        <List*/}
  {/*          disablePadding*/}
  {/*          subheader={*/}
  {/*            <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>*/}
  {/*              Before that*/}
  {/*            </ListSubheader>*/}
  {/*          }*/}
  {/*        >*/}
  {/*          {notifications.slice(2, 5).map((notification) => (*/}
  {/*            <NotificationItem key={notification.id} notification={notification} />*/}
  {/*          ))}*/}
  {/*        </List>*/}
  {/*      </Scrollbar>*/}

  {/*      <Divider />*/}

  {/*      <Box sx={{ p: 1 }}>*/}
  {/*        <Button fullWidth disableRipple component={RouterLink} to="#">*/}
  {/*          View All*/}
  {/*        </Button>*/}
  {/*      </Box>*/}
  {/*    </MenuPopover>*/}
    </>
  );
}
