import {createSlice} from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
import parseQueryParams from 'src/utils/query';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  notifications: [],
  totalUnRead: 0,
  totalNotifications: 0
};

const slice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET NOTIFICATIONS
    getNotificationsSuccess(state, action) {
      state.isLoading = false;
      state.notifications = action.payload;
    },

    setTotalUnRead(state, action) {
      state.totalUnRead = action.payload;
    },

    setTotalNotifications(state, action) {
      state.totalNotifications = action.payload;
    },

    setNotifications(state, action) {
      state.notifications = action.payload;
    },
  }
});

// Reducer
export default slice.reducer;

// Actions
export const {
  getNotificationsSuccess,
  setTotalUnRead,
  setTotalNotifications,
  setNotifications,
} = slice.actions;

// ----------------------------------------------------------------------

export function getNotifications(queryParams) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`notifications/notification/?${parseQueryParams(queryParams)}`);
      dispatch(slice.actions.getNotificationsSuccess(response.data.results));
      dispatch(slice.actions.setTotalNotifications(response.data.count));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

