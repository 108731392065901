// routes
import Router from './routes';
// theme
import ThemeConfig from './theme';
import GlobalStyles from './theme/globalStyles';
// hooks
import useAuth from './hooks/useAuth';
import {useSearchParams} from "react-router-dom";
// components
// import Settings from './components/settings';
import RtlLayout from './components/RtlLayout';
import ScrollToTop from './components/ScrollToTop';
import GoogleAnalytics from './components/GoogleAnalytics';
import NotistackProvider from './components/NotistackProvider';
import ThemePrimaryColor from './components/ThemePrimaryColor';
import ThemeLocalization from './components/ThemeLocalization';
import { BaseOptionChartStyle } from './components/charts/BaseOptionChart';
import LoadingScreen, { ProgressBarStyle } from './components/LoadingScreen';
import LogRocket from 'logrocket';
import {useCallback, useEffect, useState} from "react";
import {useDispatch, useSelector} from "./redux/store";
import {setSelectedCompany, getCompaniesSuccess} from "./redux/slices/company";
import axiosInstance from "./utils/axios";

// ----------------------------------------------------------------------


export default function App() {
  const { isInitialized, user, isAuthenticated } = useAuth();
  const dispatch = useDispatch();
  const [paramCompany, setParamCompany] = useState();
  const [searchParams, setSearchParams] = useSearchParams();
  const {selectedCompany, initialLoad} = useSelector((state) => state.company);

  useEffect(() => {
    if (!searchParams || !searchParams.get("c")) return;
    setParamCompany(searchParams.get("c"));
  }, [searchParams]);

  const _checkCompany = useCallback(async () => {
    const response = await axiosInstance.get(`/companies/`);
    const companies = response.data;
    dispatch(getCompaniesSuccess({companies, initialLoad: true}));

    if (companies?.length > 0) {
      // Auto select the only existing company
      if (companies?.length === 1) {
        dispatch(setSelectedCompany(companies[0]));
        searchParams.delete("c");
        setSearchParams(searchParams);
        return;
      }

      // Search for company in search query params
      if (!paramCompany) return;
      const decodedCompany = decodeURIComponent(paramCompany);

      if (decodedCompany === selectedCompany?.name) return;
      const foundCompany = companies.find(c => c.name === decodedCompany);

      if (foundCompany) {
        dispatch(setSelectedCompany(foundCompany));
      }
    }
  }, [dispatch, paramCompany, searchParams, selectedCompany?.name, setSearchParams]);

  useEffect(() => {
    if (isInitialized && isAuthenticated && !initialLoad) {
      _checkCompany();
    }
  }, [initialLoad, isInitialized, isAuthenticated, _checkCompany, dispatch]);

  useEffect(() => {
    if (isAuthenticated && user?.first_name && !user?.is_superuser) {
      LogRocket.init('xrs4j1/onice-muebles');
      LogRocket.identify(`${user.id}: ${user.email}`, {
        name: `${user.first_name} ${user.last_name}`,
        email: user.email,
        company: user.company?.name || 'No Company'
      });
    }
  }, [isAuthenticated, user]);

  return (
    <ThemeConfig>
      <ThemePrimaryColor>
        <ThemeLocalization>
          <RtlLayout>
            <NotistackProvider>
              <GlobalStyles />
              <ProgressBarStyle />
              <BaseOptionChartStyle />
              {/* <Settings /> */}
              <ScrollToTop />
              <GoogleAnalytics />
              {isInitialized ? <Router /> : <LoadingScreen />}
            </NotistackProvider>
          </RtlLayout>
        </ThemeLocalization>
      </ThemePrimaryColor>
    </ThemeConfig>
  );
}
